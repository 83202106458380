import React from 'react';
import classnames from 'classnames';
import { shape, string, object, number } from 'prop-types';
import { Image as ImageNordic } from 'nordic/image';
import { Card as AndesCard } from '@andes/card';
import { Button } from '@andes/button';
import withTracker from '../../../with-tracker';
import withTrackerOnPrint from '../../../with-tracker-on-print';
import { trackEvent } from '../../../../../services/tracking';
import { LAZYLOAD_OFF, LAZYLOAD_ON, LAZYLOAD_THRESHOLD } from '../../../../../../utils/constants';

const namespace = 'dynamic-access-card-poster-item';

const CardPoster = ({ card, track, sizeCard, index }) => {
  const { text, picture, title, permalink } = card;
  const handleClick = () => {
    trackEvent(track);
  };
  const isLazy = (currentIndex) => (currentIndex < LAZYLOAD_THRESHOLD ? LAZYLOAD_OFF : LAZYLOAD_ON);

  return (
    <AndesCard
      className={classnames(
        'dynamic-access-card',
        namespace,
        `dynamic-access-card__${sizeCard}`,
      )}
    >
      {picture && picture.url && (
        <div className={`${namespace}__image`} aria-hidden="true">
          <ImageNordic
            src={picture.url.src}
            width="100%"
            height="100%"
            lazyload={isLazy(index)}
            preload={index < 6}
          />
        </div>
      )}
      {text && title && (
        <div className={`${namespace}__info`}>
          <div className={`${namespace}__description-content`}>
            <Button
              hierarchy="loud"
              size="small"
              fullWidth
              onClick={handleClick}
              href={permalink}
              className="description-content__link"
              title={title}
            >
              {text}
            </Button>
          </div>
        </div>
      )}
    </AndesCard>
  );
};

CardPoster.propTypes = {
  index: number,
  card: shape({
    permalink: string,
    picture: shape({
      url: shape({
        src: string,
      }),
    }),
    text: string,
    title: string,
  }),
  track: object,
  sizeCard: string,
};

export default withTracker(withTrackerOnPrint(CardPoster));
