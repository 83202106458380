import React from 'react';
import { string } from 'prop-types';
import { Image } from 'nordic/image';
import classnames from 'classnames';

import { DEVICE_TYPE } from '../../../../../utils/constants';

const namespace = 'ui-homes-icon ui-homes-icon--da-mlb-low-price-products';

const IconMlbLowPriceproducts = ({ className = null, deviceType = DEVICE_TYPE.DESKTOP, alt = '' }) => (
  <Image
    className={classnames(namespace, className)}
    src={`dynamic-access-${deviceType}/mlb-low-price-product.svg`}
    alt={alt}
    lazyload="off"
    preload
  />
);

IconMlbLowPriceproducts.propTypes = {
  className: string,
  deviceType: string,
  alt: string,
};

const Icon = React.memo(IconMlbLowPriceproducts);
Icon.ICON_ID = 'home-da-new-mlb-lowpriceproducts';

export default Icon;
