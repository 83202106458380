import React, { useEffect, useRef } from 'react';
import { number, string, func, oneOfType, oneOf } from 'prop-types';
import classnames from 'classnames';

const namespace = 'ui-pdp-iframe';
const DEFAULT_SCROLLING = 'auto';

const Iframe = ({
  id = null,
  title = null,
  height = null,
  onLoad = null,
  onMessage = null,
  scrolling = DEFAULT_SCROLLING,
  src,
  className = null,
  ...rest
}) => {
  const iframeEl = useRef(null);

  useEffect(() => {
    function handleMessage(e) {
      if (onMessage && e.data && e.data.type) {
        onMessage(e.data.type, e.data, iframeEl.current);
      }
    }
    globalThis.addEventListener('message', handleMessage);
    return () => {
      globalThis.removeEventListener('message', handleMessage);
    };
  });

  return (
    <iframe
      id={id}
      className={classnames(namespace, className)}
      onLoad={onLoad}
      title={title}
      src={src}
      frameBorder="0"
      scrolling={scrolling}
      style={height ? { height: `${height}px` } : null}
      ref={iframeEl}
      {...rest}
    />
  );
};

Iframe.propTypes = {
  id: string,
  className: string,
  height: oneOfType([number, string]),
  scrolling: oneOf(['auto', 'yes', 'no']),
  src: string.isRequired,
  title: string,
  onLoad: func,
  onMessage: func,
};

export default React.memo(Iframe);

