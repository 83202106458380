import React from 'react';
import { string } from 'prop-types';
import { Image } from 'nordic/image';
import classnames from 'classnames';

import { DEVICE_TYPE } from '../../../../../utils/constants';

const ICON_ID = 'home-da-cellphones-category';

const namespace = 'ui-homes-icon ui-homes-icon--da-cellphones-category';

const IconCellphoneCategory = ({ className = null, deviceType = DEVICE_TYPE.DESKTOP, alt = '' }) => (
  <Image
    className={classnames(namespace, className)}
    src={`dynamic-access-${deviceType}/cellphones-category.svg`}
    alt={alt}
    lazyload="off"
    preload
  />
);

IconCellphoneCategory.propTypes = {
  className: string,
  deviceType: string,
  alt: string,
};

const Icon = React.memo(IconCellphoneCategory);
Icon.ICON_ID = ICON_ID;

export default Icon;

