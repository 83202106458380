import React, { useMemo, useState, useEffect } from 'react';
import { string, array, oneOf } from 'prop-types';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';
import DynamicAccessCardDesktop from './dynamic-access-card/desktop';

import withTracker from '../with-tracker';
import adjustCardHeightDesktop from './utils/height-in-cards-desktop';
import { slidesInDynamicAccess } from '../../../../utils/slider-per-view';

const DynamicAccessDesktop = ({ type, cards, deviceType, viewportWidth, title }) => {
  const sizeCard = useMemo(() => adjustCardHeightDesktop(cards), [cards]);
  const [newViewportWidth, setNewViewportWidth] = useState(viewportWidth || 6);

  useEffect(() => {
    setNewViewportWidth(window.innerWidth);
    const handleResize = () => {
      setNewViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section type={type} className="dynamic-access">
      <div className="container row">
        <CarouselSnapped
          srLabel={title}
          slidesPerView={slidesInDynamicAccess(newViewportWidth)}
          className={`carousel-dynamic-access-${deviceType}`}
          spacing={16}
          pagination={false}
          lazyload={false}
          arrows
        >
          {cards.map((card, index) => (
            <CarouselSnappedSlide key={card.card_id}>
              <DynamicAccessCardDesktop
                card={card}
                sizeCard={sizeCard}
                index={index}
              />
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
      </div>
    </section>
  );
};

DynamicAccessDesktop.propTypes = {
  type: string,
  cards: array,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']),
  viewportWidth: string,
  title: string,
};

export default withTracker(DynamicAccessDesktop);
